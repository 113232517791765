import React from "react"
import styled from "styled-components"

const ImgStyled = styled.div`
    
    /* Picture-Drop */
    filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.4));
    border-radius: 3px;
    box-shadow: inset -1px 1px 2px -1px #FFFFFF, inset 1px -1px 4px rgba(0, 0, 0, 0.25), inset -1px 1px 3px rgba(255, 255, 255, 0.5);

    >* {
        border-radius: 3px;
    }

`

const Canvas = (props) => {
    return (
    <ImgStyled>
        {props.children}  
    </ImgStyled>
    )
}

export default Canvas