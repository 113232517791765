import React from "react"
import styled from "styled-components"
import BasketUp from "../../../static/assets/basketUp.svg"
import BasketDown from "../../../static/assets/basketDown.svg"

const BasketButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  opacity: ${props => props.bought};
  
  :active {
    transform: scale(0.8);
  }
`

const SoldMessage = styled.div`
    font-family: ${({ theme }) => theme.font.serif};
    font-weight: 200;
    font-size: 0.75em;
    line-height: 1.55em;
    background: rgba(255, 255, 255, 0.8);
    bottom: -40px;
    width: 100%;
    height: 100%;
    position: relative;
    margin: -1em 0;
    padding: 1em 0;
    display: ${props => (props.hidden ? "none" : "block")};
`

const BuyLine = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-height: 90%;
    width: 100%;

    @media (max-width: 768px) and (orientation: portrait) {
        justify-content: space-between;
    }
`

class Basket extends React.Component {
  constructor(props){
    super(props);
    this.state = {bought: '0'};
    this.state = {buyButton: false};
    this.removeItem = this.removeItem.bind(this);
  }

  

  componentDidMount() {
    const sold = this.props.sold;
    
    document.addEventListener('snipcart.ready', () => {
      window.Snipcart.store.subscribe();
    })
    
    if (sold) {
      this.setState({buyButton: this.buyButton = true});
    }

  }

  componentWillUnmount () {
    document.addEventListener('snipcart.ready', () => {
        window.Snipcart.store.unsubscribe();
    })
  }


  removeItem() {
    const items = window.Snipcart.store.getState().cart.items.items;
    const found = items.find((item) => item.id === `${this.props.id}`);
    if (found) {
      window.Snipcart.api.cart.items.remove(found.uniqueId);
    }
  } 

 
 

  render() {
  return (
    <>
      {this.props.sold ? (
        <SoldMessage>Sorry, the red dot means this painting is already sold.</SoldMessage>
        ) : (
        <SoldMessage hidden/>
      )}
      <BuyLine>
          <BasketButton onClick={this.removeItem} aria-label="Remove Item">
            <BasketDown />
          </BasketButton>
        
          <BasketButton className='snipcart-add-item' disabled={this.buyButton}
            data-item-id={this.props.id}
            data-item-price={this.props.price}
            data-item-name={this.props.name}
            data-item-description={this.props.description}
            data-item-image={this.props.image}
            data-item-width={this.props.width}
            data-item-height={this.props.height}
            data-item-weight={this.props.weight}
            data-item-max-quantity={this.props.quantity}
            data-item-url={"https://nickygreenshields.art/" + this.props.link}
            aria-label="Add Item" onClick={()=> this.setState({bought: '1'})}>
            <BasketUp />
          </BasketButton>
      </BuyLine> 
    </>
    )
  }
}

export default Basket;